import React from "react";
import { useTranslation } from "next-i18next";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import * as yup from "yup";
import { FormikProvider, useFormik } from "formik";
import TextInputWithFormik from "../TextInput/TextInputWithFormik";
import SubmitButton from "../SubmitButton";
import { useAuth } from "../../src/context/auth";
import { ADD_FILTER } from "../../graphql/filter.graphql";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import CheckboxInput from "../CheckboxInput";

const SaveFilterModal = ({ open, toggleModal, data }) => {
  const { t } = useTranslation();

  const { userData } = useAuth();
  const [addFilter] = useMutation(ADD_FILTER);

  const formik = useFormik({
    initialValues: {
      name: "",
      saveDate: false,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const object = {
        user_id: userId,
        filter_name: values.name,
        filter_data: JSON.stringify(data),
        view: data.view,
        use_current_date: !values.saveDate,
      };

      addFilter({ variables: { object } })
        .then(() => {
          toast.success(t("general.filters.created"));
          resetForm();
          toggleModal();
        })
        .catch((e) => {
          console.log(e);
          toast.error(t("general.filters.filtersCountError"));
        });
    },
    validationSchema,
  });

  if (!userData)
      return;
  
  const { id: userId } = userData;

  const fieldRequired = t("general.validation.required");

  const validationSchema = yup.object().shape({
    name: yup.string().required(fieldRequired),
  });

  const { handleSubmit, resetForm, setFieldValue, values } = formik;

  return (
    <Modal show={open} onHide={() => toggleModal()} centered>
      <Modal.Header
        closeButton
        closeVariant="white"
        className="bg-dark"
        style={{
          borderColor: "#2253d4",
        }}
      >
        <h2 className="fw-bold text-light mb-0">
          {t("general.filters.formTitle")}
        </h2>
      </Modal.Header>
      <Modal.Body className="position-relative p-0">
        <Form
          id="timelog-form"
          noValidate
          onSubmit={handleSubmit}
          className="p-0 d-flex flex-column align-items-center"
          // style={{
          //   marginBottom: isMenuOpen ? formMarginBottom : 0,
          // }}
        >
          <FormikProvider value={formik}>
            <div className="d-flex flex-column justify-content-between align-items-start w-75 mt-2">
              <TextInputWithFormik
                label={t("general.filters.name")}
                name="name"
                required
                type="text"
                placeholder={t("general.filters.namePlaceholder")}
                containerClass="w-100 mb-3"
              />
              {!!data?.date && (
                <CheckboxInput
                  name="saveDate"
                  checked={values["saveDate"]}
                  label={t("general.filters.saveDate")}
                  onChange={(e) => {
                    setFieldValue("saveDate", e.target.checked);
                  }}
                  containerClass="d-flex justify-content-start align-items-center fw-bold"
                  style={{
                    fontSize: 14,
                  }}
                />
              )}
            </div>
          </FormikProvider>
        </Form>
      </Modal.Body>
      <Modal.Footer className="w-100 d-flex justify-content-center">
        <Button
          // disabled={loading}
          variant="secondary"
          onClick={() => toggleModal()}
        >
          {t("general.cancel")}
        </Button>
        <SubmitButton
          form="special-rate-form"
          // loading={loading}
          onClick={handleSubmit}
          label={t("general.submit")}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default SaveFilterModal;
